import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider } from '@web3-react/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

function getLibrary(provider: any) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 8000;
  return library;
}

const AppComponent = React.lazy(() => import('./App'));
const rootElement: any = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Web3ReactProvider getLibrary={getLibrary}>
        <AppComponent />
      </Web3ReactProvider>
    </BrowserRouter>
  </React.StrictMode>
);
